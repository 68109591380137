import {React,useEffect} from 'react';
import './services.css';

const Service = (props) => {
    useEffect(() => {
        var elementID = window.location.hash.substring(1);
        let my_element = document.getElementById(elementID);
        if(my_element) {
            my_element?.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
              });
        }
        },[]);

    return (
        <>
        <section className="banner ">
            <div className="img py-5 text-center">
                <h2>SERVICES</h2>
            </div>
        </section>

        <div className="container">
            <div className="row py-4">
                <div className="col-sm-12">
                    <h2 className="text-center" id="SECRETARIAL_SERVICES">SECRETARIAL SERVICES</h2>
                    <p className="text-center">SECRETARIAL SERVICES WHICH ARE PROVIDED ON A RETAINERSHIP BASIS:</p>
                    <ul>
                        <li>Advising on Company Law matters including compliances required under provisions of the Companies Act, 2013 and the rules framed thereunder;</li>
                        
                        <li>Maintenance of Statutory Records required to be maintained under the Companies Act, 2013 (i.e. Minutes Book for Board Meeting\ EGM\AGM\Committee Meetings and various statutory registers to be maintained under the Companies Act, 2013);</li>
                        
                        <li>Drafting of Resolutions, Notices, Explanatory Statements, Agenda, Minutes  as required under the Companies Act, 2013;</li>
                        
                        <li>Preparation, attestation & filing of various Forms and Documents required to be filed with the Registrar of Companies;</li>
                        
                        <li>Preparing the Annual Return, attestation and filing of Annual Return as prescribed under the Companies Act, 2013 with the Registrar of Companies;</li>
                
                        </ul>

                        <h6 className="text-center">SECRETARIAL SERVICES WHICH ARE PROVIDED ON A ASSIGNMENT BASIS WHICH INTER ALIA INCLUDE FOLLOWING:</h6>
                        <ul>
                            <li>
                                Incorporation of company & limited liability partnership (LLP);
                            </li>
                            <li>Alteration of Memorandum of Association / Alteration of Articles of Association;</li>
                            <li>Shifting of Registered office within the local limits of same city, town & village from jurisdiction of one ROC t other and shifting from one state to other </li>
                            <li>
                            Increase in authorized share capital, further issue of share capital, Buy Back of Shares, Reduction of share capital;
                            </li>
                            <li>Conversion of Private Limited to Public Limited/Public Limited to Private Limited/ Partnership firm into a Company under Part I of Chapter XXI under Companies Act, 2013;</li>
                            <li>
                                Removing of the name of the Company from Registrar of Companies i.e. striking of name of Company;
                            </li>
                            <li>Secretarial Audit / Internal Audit;</li>

                            <li> Due Diligence of Secretarial Records; </li>
                            
                            <li>Compounding of offence under provisions of Companies Act, 2013; </li>
                            
                            <li>Condonation of Delay;</li>
                            
                            <li>XBRL Development and Filing;</li>
                        </ul>
                        
                </div>
                
            </div>

            <div className="row py-4">
                <div className="col-sm-12" id="RBI">
                    <h2 className="text-center">PART B: RBI/ SEBI/ FEMA RELATED SERVICES</h2>
                    <ul>
                        <li> Incorporation and Registration of Non-Banking Financial Companies (NBFCs) with RBI and taking care of regular compliances; </li>

                            <li> Permission from Reserve Bank India (RBI) for issue or transfer of shares to Overseas Corporate Bodies / foreign nationals / NRIs, wherever applicable; </li>
                        
                            <li>Due Diligence require by RBI in case of consortium finance; </li>
                        
                            <li> Preparing and submitting Form FC-GPR with RBI for Allotment of Shares to Non Residents; </li>
                        
                            <li>Issuing of CS Certificate required to be submitted along with Form FC-GPR; </li>
                        
                            <li> Preparing of Annual Return on Foreign Assets & Liabilities as per FEMA; </li>
                        
                            <li> RBI approval for opening branch office/liaison office in India by a foreign Company; </li>	
                            <li> Assistance in handling matters under Foreign Exchange Management Act (FEMA); </li>
                        
                            <li> Issuance of Certificate under Regulation 40(9) of SEBI (LODR), Regulations, 2015 to listed companies; </li>
                        
                            <li> Issuance of Share Reconciliation Audit Report as per Depository Act to listed companies; </li>
                        
                            <li> Issuance of various certificates and reports to listed entities as required under various provisions of SEBI Regulations. </li>
                        </ul>
                </div>
                
            </div>

            <div className="row py-4">
                <div className="col-sm-12" id="LEGAL">
                    <h2 className="text-center">PART C: LEGAL SERVICES</h2>
                    <h6 className="text-center">Filing of Petition and Applications</h6>
                    <ul>
                        <li>Compounding Application </li>
                        <li>Oppression Mismanagement</li>
                        <li>Petition for condonation of delay for filing of charge </li>
                        <li>Change of Registered office from one state to another </li>
                        <li>Petition for revival of order </li>
                        <li>Petition for rectification of register of members </li>
                        
                        </ul>
                        <h6 className="text-center">Representations and obtaining approvals from </h6>
                        <ul>
                            <li>Ministry of Corporate Affairs (MCA) </li>
                            <li> National Company Law Tribunal (NCLT) </li>
                            <li> Regional Director (RD) </li>
                            <li> Official Liquidator (OL) </li>
                            <li> Registrar of Companies (ROC) </li>
                            <li> Reserve Bank of India (RBI) </li>
                            <li> Securities & Exchange Board of India (SEBI) </li> 
                            <li> Stock Exchanges (NSE /BSE) </li>
                            <li> National Company Law Tribunal for Amalgamation / Mergers / Demerger ,Arrangement in relation to corporate restructuring, Reduction of Share Capital ,Voluntary liquidation/Liquidation & Winding-up of Companies by Courts </li>
                            <li> Appearance during the Opposition proceedings in respect of Trademark, Copyright, Patent and Design in India at the respective regional offices of Intellectual Property </li>
                            <li> Other Government and Semi–Government bodies</li>
                        </ul>
                </div>
            
            </div>

            <div className="row py-4">
                <div className="col-sm-12">
                    <h2 className="text-center">PART D: MISCELLANEOUS SERVICES</h2>
                    <ul>
                        <li> Registration / Renewal of Trade mark; </li>

                    <li> Diligence Report for Banks Status/ Search Reports for banks/ Certification of documents relating to charges; </li>
                        
                    <li> Internal Audit of Stock Broking Firms (By a Practising Company Secretary); </li>
                        
                    <li> Assistance in preparation of Annual Report of Listed Companies including Business Responsibility and Sustainability Report (BRSR Report); </li>
                        
                    <li> Corporate Restructuring Assignments; </li>
                        
                    <li> Acting as Scrutinizer for Postal Ballot for listed Companies; </li>
                        
                    <li> Listing of shares on BSE, NSE, interconnected Stock Exchanges and Regional stock exchanges  /securities issued on Preferential/Private Placement basis; </li>
                        
                    <li> Listing of shares arising out of Conversion of Debentures/Warrants/Notes/Bonds into Equity Shares; </li>
                        
                    <li> Intellectual Property Rights - Application preparatory, submission and follow-up for Trade Marks and Certification Marks Registration, Copyright Registration, Patents Registrations, Design Registration, Drafting and vetting of License/Assignment Agreement for Trade Mark, Copyright and Patent & Conduct IPR Due Diligence; </li>
                        
                        <li> Application for Import Export Code (ICE); </li>
                        
                        <li> Application for Food License form (FSSAI) </li>
                        
                        <li> Application for License under The Shops and Establishments Act; </li>
                        
                        <li> Charge Registrations and search reports for Banks and financial Institutions; </li>
                        
                        <li> Inspection of Charges registered with Registrar of Companies; </li>
                        
                        <li> Inspection of the Documents of the Company filed with the Registrar of Companies, preparing Search Reports and obtaining certified true copy of the documents from ROC; </li>
                        
                        <li> Inspection of the Documents at Registrar of Firms, preparing Search Reports and obtaining certified true copy of the extract from Registrar of Firms; </li>
                        
                        <li>  Matters relating to the Insolvency and Bankruptcy Code, 2016; </li>
                        
                        <li> Matters related to Nation Company Law Tribunal. </li>
                        </ul>
                </div>
                
            </div>

        </div>
        </>
    )
}

export default Service;
