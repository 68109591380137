import React from 'react';
import "./home.css";
import { useNavigate} from "react-router-dom";

const Home = (props) => {
  const navigate = useNavigate();
  
    const redirectTo = (id) => {
      // e.preventDefault();
      if(id) {
        navigate('/service#'+id);
      }  else {
        navigate('/service');
      }
  };


  const goToAbout=()=>{
    navigate("/about");
  }
    return (
        <>
        
<section className="banner">
  <h1 className="banner-text">Aspiring Businesses</h1>
    <img src="../assets/banner.jpg" alt="" className="w-100" />
  </section>
  <section className="mt-4 py-5">
    <div className="container">
      <div className="row">
        <div className="col-sm-12 text-sm-center">
          <h4>10+ Years of
            Experience in Corporate and Allied Law</h4>
            <p>
              Nirmal Patel and Associates is a Practicing Company Secretaries Firm with a team of experienced professional individuals who put the clients’ interests at the forefront.
            </p>
            <p>
              Driven by a passion for excellence and a dedication to our clients, we go beyond the traditional boundaries of legal consultancy. Our collaborative approach, combined with a deep understanding of both legal intricacies and business dynamics, enables us to offer innovative strategies and actionable insights that propel you towards your objectives.
            </p>
            <button className="btn btn-primary custom-btn" onClick={()=>goToAbout()} >
              MORE ABOUT US
            </button>
        </div>
      </div>
    </div>
  </section>

  <section className="services">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2 className="text-center mb-5">RANGE OF SERVICES </h2>
          <div className="row">
            <div className="col-6">
              <div className="service-block service" onClick={() => redirectTo('SECRETARIAL_SERVICES')}>
                <img src="./../../../assets/services.png" alt="service" />
                <p>SECRETARIAL SERVICES</p>
              </div>
            </div>
            <div className="col-6">
              <div className="service-block" onClick={() => redirectTo('RBI')}>
                <img src="./../../../assets/rbi.png" alt="service" />
                <p>RBI & FEMA SERVICES</p>
              </div>
            </div>
            <div className="col-6">
              <div className="service-block" onClick={() => redirectTo('LEGAL')}>
                <img src="./../../../assets/legal.png" alt="service" />
                <p>LEGAL SERVICES</p>
              </div>
            </div>
            <div className="col-6">
              <div className="service-block" onClick={() => redirectTo('RBI')}>
                <img src="./../../../assets/sebi.png" alt="service"/>
                <p>SEBI REGULATIONS</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

        </>
    )
}

export default Home;