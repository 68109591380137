import './App.css';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Contact from './components/Contact';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

function App() {
  return (
    <div className="App">
            <Header/>
              <Contact/>
            <Footer/>
     
    </div>
  );
}

export default App;
