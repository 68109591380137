import {React} from 'react';
import {
  Link,
  NavLink,
  Routes,
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import "./header.css"
import About from '../About';
import Home from '../Home';
import Service from '../Services';


const Header = () => {
 

    return (
        <>
         <Router>
          <nav className="navbar bg-primary navbar-expand-lg bg-body-tertiary" data-bs-theme="light">
            <div className="container-fluid">
              <Link className="navbar-brand logo-img" to="/"> 
                <img src="../../../assets/cs-logo.png" alt="cs nirmal patel" />
                <span className="logo-name">Nirmal Patel & Associates <br/> <p className="header-cs-text">Practicing Company Secretaries</p> </span> 
              </Link>
              <button className="navbar-toggler toggle_btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse pr-5" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <NavLink className="nav-link" aria-current="page" to="/" >Home</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/service" >Services</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/about" >About</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </nav> 
          <Routes>
          <Route path="/about"  element={<About />} />
          <Route path="/" element={<Home />} />
          <Route path="/service" element={<Service />} />
          {/* <Route path="*" element={<NoPage />} /> */}
      </Routes>
        </Router>
        </>
    )
}

export default Header;
