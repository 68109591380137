import React from 'react';
import "./footer.css"

const Footer = (props) => {
    return (
        <>
        <footer className="pt-0">
            <div className="container">
            </div>
            <div className="ft-bottom  py-4">
            <div className="container">
            <div className="row">
                <div className="col-sm-6">
                <h5 className="logo">Nirmal Patel & Associates <br/> <p className="cs-text">Company Secretaries</p></h5>
                </div>
                <div className="col-sm-6 text-right">
                <h6>N. P. and Associates</h6>
                <p>website by Atul Patel IT Solutions</p>
                </div>
            </div>
            </div>
            </div>
        </footer>
        </>
    )
}

export default Footer;