import React from 'react';
// import { Movies } from '../../../json';

const About = (props) => {
    return (
        <>
      <section className="banner ">
    <div className="img py-5 text-center">
        <h2>BRIEF PROFILE </h2>
    </div>
</section>

<div className="container">
    <div className="row py-4">
        <div className="col-sm-12">
            <h3 className="text-center">M/s Nirmal Patel & Associates</h3>
            <p>
                M/s Nirmal Patel & Associates is proprietary concern dealing & handling Legal, Secretarial, and Compliance related work on PAN India basis.
            </p>
            <p>
                Mr. Nirmal Patel founder of M/s Nirmal Patel & Associates is Fellow Member of Institute of Company Secretaries of India, having years of work experience of secretarial legal and field in different sector includes financial sector, Pharmaceuticals, Real Estate, Hospitality, Trading etc.
            </p>
            <p>
                M/s Nirmal Patel & Associates provides Legal, Secretarial, Compliance and Corporate Advisory services, it also advice & assist in setting up of business and help them to comply with the all applicable Laws.
            </p>

            <p>
                We at M/s Nirmal Patel & Associates believe in providing qualitative, prompt and proficient deliverables and our vision is to aspire, accelerate, enhance and propagate across the globe.
            </p>
            <p>
                A long- term growth oriented and contemporary outlook of the associates having varied experience ensures a continued growth oriented professional momentum. It has a team of various Associates empanelled with it.  It has bandwidth to execute all jobs efficiently and effectively. 
            </p>
            <p>
                M/s Nirmal Patel & Associates has good acquaintances with the Ministry of Corporate Affairs, Securities Exchange Board of India (SEBI), Reserve Bank of India RBI), SEBI, Primary & Secondary market intermediaries across India, Financial Institution and other Governmental & Semi Governmental Agencies.
            </p>
        </div>
        
    </div>
</div>
<section className="py-5 global-clients">
    <div className="container">
        <h3 className="text-center">Global Clients</h3>
        <p className="text-center">
            We offer our services to global locations. You can avail our offerings from wherever your business is setup.
        </p>
        <div className="row">
            <div className="col-sm-6">
                <img src="./../../../assets/map.png" alt="" className="img-fluid" />
            </div>
            <div className="col-sm-6">
                <div className="row">
                    <div className="col-sm-6">
                        <ul>
                            <li>China</li>
                            <li>Denmark</li>
                            <li>Finland</li>
                            <li>France</li>
                            <li>Germany</li>
                            </ul>
                    </div>
                    <div className="col-sm-6"><ul>
                        <li>Netherlands</li>
                        <li>Russia</li>
                        <li>Singapore</li>
                        <li>South Korea</li>
                        <li>Sweden</li>
                        </ul></div>
                </div>
            </div>
        </div>
    </div>
</section>
        </>
    )
}

export default About;