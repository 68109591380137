import {React,useState} from 'react';
import "./contact.css"
import emailjs from '@emailjs/browser';


const Contact = (props) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [queary, setQueary] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('dsadasd');
        formReset()
        emailjs.init("jYpjIgwyS04vvUSTL");
        await emailjs.send("service_gh4lyl5","template_qffbiho",{
            message: queary,
            subject: 'Enquiry',
            to_name: 'Nirmal',
            from_name: name,
            from_email: email,
            }).then(() => {
              alert('Thanks you for your mail !');
              formReset();
            },
            (error) => {
                alert('Please Retry'); 
            });
    };
    const formReset = () => {
        setName("")
        setEmail('');
        setQueary('');
    }
 
  

    return (
        <>
        <section className="banner ">
            <div className="img pt-5 text-center">
                <h2>Get in touch with us</h2>
            </div>
        </section>
        <section className="">
            <div className="container">
                <div className="contact-area shadow-sm p-sm-5 p-4 bg-body-tertiary rounded bg-warning-subtle">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3>Contact Nirmal Patel</h3>
                            <p>
                                You can drop an email and we’ll get in touch shortly, or call us between 9:30 am and 6:00 pm, Monday to Friday.
                            </p>
                            <div className="mt-5 address-section">
                                <p>
                                    <b>Head Office</b> : House No.70/1, Near Post office Atali, Ambivali Station West, Kalyan - 421102 - Maharashtra                            
                                </p>
                                <p>
                                    <b>Branch Office</b> : 209, Shekhar Central, Manoramaganj, Indore - Madhya Pradessh - 452001
                                </p>
                                <p>
                                    <b>Timings </b>: Mon - Fri 9:30 AM - 6:00 PM
                                </p>
                                <p>
                                    <b>Phone</b>: <a href="tel:+919893352996">+91 9893352996</a> 
                                </p>
                                <p><b>Email</b> : <a href="mailto:nirmalpatel@csnpa.in">nirmalpatel@csnpa.in</a> </p>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <form action="javascript:void(0)"
                            >
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Your Name</label>
                                    <input type="text" className="form-control" value={name} name="from_name" id="name" required onChange={(e) =>
                                            setName(e.target.value)
                                        } />
                                    <div id="nameHelp" className="form-text">We'll never share your email with anyone else.</div>
                                </div>
                                <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                <input type="email" className="form-control" name="from_email" id="exampleInputEmail1" aria-describedby="emailHelp" value={email} required onChange={(e) =>
                                        setEmail(e.target.value)
                                    } />
                                <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                                </div>
                                <div className="mb-3">
                                <label htmlFor="yourquery" className="form-label">Your query</label>
                                <textarea cols="40" rows="6" className="form-control" aria-invalid="false" value={queary}  name="your-query" required onChange={(e) =>
                                        setQueary(e.target.value)
                                    } ></textarea>
                                </div>
                                <button type="submit" onClick={(e) => handleSubmit(e)} className="btn btn-primary custom-btn">Submit</button>
                            </form>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Contact;